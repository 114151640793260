<template>
  <div class="container">
    <div class="work-place-setup">
      <!-- <div><h3 class="title">Dashboard设置</h3></div> -->
      <h4>参与统计的车辆选择</h4>
      <span class="remark">被选中的车辆，其数据将被统计、展示在dashboard中</span>
      <div style="margin-top: 10px">
        <object-tree
          style="height: 50vh"
          ref="vehicleTree"
          :tree-data="treeData"
          place-holder="输入车牌或车辆自编号进行搜索"
          object-title="自动驾驶车辆"
          :cache-key="cacheKey"
          object-icon="vehicleAutoIcon"
          @check="handleCheckVehicle"
        />
      </div>
      <a-divider style="height: 1px; margin: 10px 0" />
      <h4 style="margin-top: 0px">统计区间选择</h4>
      <span class="remark">日期区间内的数据将被统计展示。 由于有多项数据是按周统计的，若所选日期落在某个周内<br />（例如周三），系统会自动调整，补全该周。补全规则：从周一开始，周日结束。</span>
      <a-radio-group v-model="mode" class="mode">
        <a-radio :value="1" :style="radioStyle">
          <span class="label">模式1:</span>
          <a-date-picker style="width: 250px" :disabled="mode === 2" v-model="beginDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear /> ~ 至今
        </a-radio>
        <a-radio :value="2" :style="radioStyle">
          <span class="label">模式2:</span>
          <a-range-picker :disabled="mode === 1" style="width: 250px; margin-top: 10px" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear />
        </a-radio>
      </a-radio-group>
      <div class="bottom-control" style="margin-top: 30px">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="handleCancel"> 取消 </a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>
<script>
import ObjectTree from '@/views/monitor/monitorComponents/objectTree'
import { getVehicleTree, getDefaultConfig, defaultConfigUpdate } from '@/api/iot/vehicle'
export default {
  components: {
    ObjectTree
  },
  data() {
    return {
      treeData: [],
      dateRange: [],
      currentCheckedList: [],
      cacheKey: 'vehicleDashboardDefaultSetupTree',
      beginDate: this.parseDateTime(new Date(), 'yyyy-MM-dd'),
      endDate: this.parseDateTime(new Date(), 'yyyy-MM-dd'),
      mode: 1,
      radioStyle: {
        display: 'block',
        height: '40px',
        lineHeight: '40px'
      }
    }
  },
  mounted() {
    this.getConfig(this.initVehicleTree)
  },
  methods: {
    getConfig(fn) {
      getDefaultConfig().then((res) => {
        const data = res.data
        this.beginDate = data.beginDate
        this.endDate = data.endDate
        this.mode = data.mode
        if (this.mode === 2) {
          this.dateRange = []
          this.dateRange.push(this.beginDate)
          this.dateRange.push(this.endDate)
        }
        this.currentCheckedList = data.vehicleIds
        this.setDefaultCheckData()
        fn && fn()
      })
    },
    setDefaultCheckData() {
      if (!this.currentCheckedList) {
        return
      }
      const hostname = location.hostname
      let tenantCode = 'yw'
      if (hostname.split('.').length === 4) {
        tenantCode = hostname.split('.')[0]
      }
      const storeKey = tenantCode + '-' + this.cacheKey + '-lastCheckedInfo'
      // 记忆上一次退出的数据
      if (!this.cacheKey) {
        return
      }
      const lastCheckedInfo = {
        currentCheckedKeys: this.currentCheckedList.map((p) => 't-1-' + p)
      }
      localStorage.setItem(storeKey, JSON.stringify(lastCheckedInfo))
    },
    initVehicleTree() {
      getVehicleTree().then((res) => {
        if (res.data) {
          this.treeData = res.data
        }
      })
    },
    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    handleCheckVehicle(checkedList, selectedId, isSameList) {
      console.log('handleCheckVehicle', checkedList)
      this.currentCheckedList = checkedList
    },
    submitForm: function () {
      if (this.mode === 1) {
        if (!this.beginDate) {
          this.$warningEx('请选择统计开始日期！')
          return
        }
      } else {
        if (this.dateRange.length === 0) {
          this.$warningEx('请选择统计区间！')
          return
        }
        this.beginDate = this.dateRange[0]
        this.endDate = this.dateRange[1]
      }
      const params = {
        beginDate: this.beginDate,
        endDate: this.endDate,
        vehicleIds: this.currentCheckedList,
        mode: this.mode
      }
      if (this.mode === 1) {
        delete params.endDate
      }
      defaultConfigUpdate(params).then((response) => {
        this.$message.success('保存成功', 3)
        this.open = false
        this.$emit('ok', this.beginDate, this.endDate, this.currentCheckedList)
      })
    },
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  margin: 0;
  .title {
    text-align: center;
  }
  .work-place-setup {
    display: flex;
    padding-bottom: 30px;
    // padding: 30px;
    background-color: #fff;
    width: 100%;
    // margin-left: 30%;
    flex-direction: column;
    justify-content: space-between;
    .remark {
      margin-top: -5px;
      font-size: 11px;
      color: #b5b5b5;
    }
    .date-item {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .date-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {
          flex: 0 0 75px;
        }
      }
    }

    .mode {
      margin-top: 15px;
      .label {
        padding-right: 10px;
      }
    }
  }
}
</style>
